<template>
  <div class="my-content">

  </div>
</template>

<script>

export default {
  name:'dashboard'
}
</script>

<style lang="scss" scoped>

</style>
